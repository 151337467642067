import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import "./../../css/ForgotPassword.css"

const ForgotPassword = () => {
    return (
        <main className="forgot-password mx-auto row text-white">
            <section className="col d-flex justify-content-end">
                <h1 id="bienvenida" className="m-0">"Reserva <span style={{ color: '#f3b945', fontSize: '70px' }}>fácil </span> e infórmate del estado y disponibilidad de cada equipo de nuestro Centro de Operaciones y Logística (COL) de Ecoturismo".</h1>
            </section>
            <section className="col d-flex justify-content-start">
                <ForgotPasswordForm />
            </section>
        </main>
    )
}

export default ForgotPassword