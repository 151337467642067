import React, { useState, useEffect } from "react";
import Layout from "../../hocs/Layout";
import '../../css/FechaHorario.css';
import MultiDatePicker from "../../components/MultiDatePicker/MultiDatePicker";
import Horarios from "../../components/Horarios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getSolicitud, updateDateTime } from "../../redux/actions/solicitud";

const FechaHorario = ({ pedido, fecha_creacion, fecha_resolucion, updateDateTime, getSolicitud }) => {
    const [currentDates, setCurrentDates] = useState({
        startDate: null,
        endDate: null
    });
    const [timeState, setTimeState] = useState({
        startTime: '',
        endTime: '',
        isValid: false
    });
    
    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startDate = fecha_creacion ? new Date(fecha_creacion) : today;
        const endDate = fecha_resolucion ? new Date(fecha_resolucion) : today;
    
        setCurrentDates({
            startDate,
            endDate
        });
    }, [fecha_creacion, fecha_resolucion]);
    
    const handleMultiDatePickerChange = (values) => {
        setCurrentDates(values);
        setTimeState(prev => ({...prev, isValid: false}));
    };
    
    const handleTimeChange = (timeData) => {
        setTimeState(timeData);
        
        if (timeData.isValid && currentDates.startDate && currentDates.endDate) {
            const startDateTime = new Date(currentDates.startDate);
            const endDateTime = new Date(currentDates.endDate);
            
            const [startHours, startMinutes] = timeData.startTime.split(':');
            const [endHours, endMinutes] = timeData.endTime.split(':');
            
            startDateTime.setHours(parseInt(startHours), parseInt(startMinutes), 0, 0);
            endDateTime.setHours(parseInt(endHours), parseInt(endMinutes), 0, 0);
            
            updateDateTime(startDateTime.toISOString(), endDateTime.toISOString());
        }
    };

    return (
        <Layout>
            <main className="fecha_horario">
                <div className="container">
                    <section className="fecha_horario_seleccion">
                        <MultiDatePicker
                            onChange={handleMultiDatePickerChange}
                            fechaCreacion={fecha_creacion}
                            fechaResolucion={fecha_resolucion}
                        />
                        {currentDates.startDate && currentDates.endDate && (
                            <Horarios 
                                onChange={handleTimeChange}
                                startDate={currentDates.startDate}
                                endDate={currentDates.endDate}
                            />
                        )}
                    </section>
                    <section className="fecha_horario_botones">
                        <Link to={`../request/${pedido}`}>
                            <button className='button'>Volver</button>
                        </Link>
                        <Link to='/carroproductos'>
                            <button className='button' disabled={!timeState.isValid}>
                                Continuar
                            </button>
                        </Link>
                    </section>
                </div>
            </main>
        </Layout>
    );
};

const mapStateToProps = state => ({
    pedido: state.Solicitud.pedido,
    fecha_creacion: state.Solicitud.fecha_creacion,
    fecha_resolucion: state.Solicitud.fecha_resolucion
});

export default connect(mapStateToProps, {
    getSolicitud,
    updateDateTime
})(FechaHorario);