import '../css/CheckList.css';
import React, { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import Confirmar from './Confirmar';

const Checklist = ({ mostrarCheckList, setMostrarCheckList, solicitud }) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
      };
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return format(date, 'HH:mm');
      };

    const categoryRef = useRef(null);

    const handleClickOutside = (event) => {
        if (categoryRef.current && !categoryRef.current.contains(event.target)) {
            setMostrarCheckList(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [showConfirmar, setShowConfirmar] = useState(false);

    const handleAbrirConfirmar = (valor) =>{
        setShowConfirmar(valor)
    }
    const handleMostrarCheckList = () =>{
        setMostrarCheckList(false)
    }
    const handleVolverClick = () => {
        setMostrarCheckList(true);
    };

    return (
        <>
            {mostrarCheckList && (
                <div className='checklist_bg'>
                    <div className="checklist" ref={categoryRef} onClick={(e) => e.stopPropagation()}>
                        <h1>Confirma el detalle de tu solicitud</h1>
                        <div className='checklist_container'>
                            <p><b>Taller:</b> {solicitud.taller}</p>
                            <p><b>Fecha de retiro:</b> {formatDate(solicitud.fecha_creacion)}</p>
                            <p><b>Hora de retiro:</b> {formatDateTime(solicitud.fecha_creacion)}</p>
                            <p><b>Fecha de devolucion:</b> {formatDate(solicitud.fecha_resolucion)}</p>
                            <p><b>Hora de devolucion:</b> {formatDateTime(solicitud.fecha_resolucion)}</p>
                            <p><b>Productos:</b> {solicitud.carrito.items.length}</p>
                            <div className='checklist_button'>
                                <button onClick={() => { handleMostrarCheckList(); }}>Volver</button>
                                <button  onClick={() => { handleAbrirConfirmar(true); handleMostrarCheckList(); }}>Enviar Solicitud</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                

            )}
            {showConfirmar&&(
                        <Confirmar pedido={solicitud} setShowConfirmar={setShowConfirmar} />
                    )}
        </>
    );
};
const mapStateToProps = state =>({
    solicitud: state.Solicitud,
});

export default connect(mapStateToProps, {

  })(Checklist);
