export default function Paginator({ currentPage, handlePageChange, totalPageCount }) {
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPageCount) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <section>
      <div className='paginator'>
        <button onClick={goToPreviousPage} className='paginator-button' disabled={currentPage === 1}>
          {'<'}
        </button>
        
        <div>
          <input
            type='number'
            value={currentPage}
            onChange={(ev) => {
              const page = parseInt(ev.target.value);
              if (page >= 1 && page <= totalPageCount) {
                handlePageChange(page);
              }
            }}
            className='paginator-input'
            style={{ width: '70px' }}
            min={1}
            max={totalPageCount}
          />
        </div>
        
        <button onClick={goToNextPage} className='paginator-button' disabled={currentPage === totalPageCount}>
          {'>'}
        </button>
      </div>
      <div className='paginator-page-selector'>
        <p>
          Página {currentPage} de {totalPageCount}
        </p>
      </div>
    </section>
  );
}