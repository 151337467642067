import React from "react";
import { connect } from "react-redux";
import Layout from "../../hocs/Layout";
import '../../css/Carrito.css'
import Productscarrito from "../../components/Productscarrito";

const Carro = ({ pedido }) => {
    return (
      <Layout>
          <Productscarrito pedidoId={pedido} />
      </Layout>
    );
}

const mapStateToProps = state => ({
    pedido: state.Solicitud.pedido
});

export default connect(mapStateToProps)(Carro);