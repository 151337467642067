import "./DropdownButton.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const DropdownButton = ({ children, open, toggle }) => {
  return (
    <div
      onClick={toggle}
      className={`dropdown-btn ${open ? "button-open" : ""}`}
    >
      {children}
      <span
        className="toggle-icon"
        style={{ pointerEvents: "none" }} // This allows clicks to pass through
      >
        {open ? <FaChevronUp /> : <FaChevronDown />}
      </span>
    </div>
  );
};

export default DropdownButton;