import React, { useState } from 'react';

const Horarios = ({ onChange, startDate, endDate }) => {
  const [times, setTimes] = useState({
    startTime: '',
    endTime: ''
  });

  const getFullTimestamp = (date, timeStr) => {
    if (!date || !timeStr) return null;
    const [hours, minutes] = timeStr.split(':').map(Number);
    const timestamp = new Date(date);
    timestamp.setHours(hours, minutes, 0, 0);
    return timestamp;
  };

  const handleTimeChange = (field, value) => {
    const newTimes = { ...times, [field]: value };
    setTimes(newTimes);

    if (newTimes.startTime && newTimes.endTime && startDate && endDate) {
      const startTimestamp = getFullTimestamp(startDate, newTimes.startTime);
      const endTimestamp = getFullTimestamp(endDate, newTimes.endTime);
      
      onChange({
        startTime: newTimes.startTime,
        endTime: newTimes.endTime,
        isValid: startTimestamp && endTimestamp && endTimestamp > startTimestamp
      });
    } else {
      onChange({
        startTime: newTimes.startTime,
        endTime: newTimes.endTime,
        isValid: false
      });
    }
  };

  const isValidTimeRange = () => {
    if (!times.startTime || !times.endTime || !startDate || !endDate) return true;
    
    const startTimestamp = getFullTimestamp(startDate, times.startTime);
    const endTimestamp = getFullTimestamp(endDate, times.endTime);
    
    return startTimestamp && endTimestamp && endTimestamp > startTimestamp;
  };

  const containerStyle = {
    position: 'relative',
    width: '645px',
    paddingTop: '30px',
    marginTop: '30px'
  };

  const titleStyle = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#1AA29B',
    borderRadius: '40px',
    padding: '10px 30px',
    zIndex: 10,
    minWidth: '200px'
  };

  const contentStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50px',
    padding: '40px 32px 32px 32px',
    width: '100%'
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>
        <h2 className="text-xl font-semibold text-white text-center uppercase" style={{fontSize: "2.5rem"}}>
          Horarios
        </h2>
      </div>

      <div style={contentStyle}>
        <div className="space-y-8">
          <div className="time-input-group">
            <label className="block text-sm font-medium mb-3 uppercase" style={{fontSize: "1.5rem"}}>
              <b>Retiro:</b> {startDate && (
                  new Date(startDate).toLocaleDateString('es-ES', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })
              )}
            </label>
            <div className="relative" style={{
                  textAlign:'center'
                }}>
              <input
                type="time"
                value={times.startTime}
                onChange={(e) => handleTimeChange('startTime', e.target.value)}
                className="w-full px-6 py-4 border-2 border-[#1AA29B] rounded-[40px] focus:ring-2 focus:ring-[#1AA29B] focus:border-[#1AA29B] transition-colors text-lg"
                step="300"
                style={{
                  position: 'relative',width:"10vw",textAlign:'center', height:"5vh"
                }}
              />
              <style>
                {`
                  input[type="time"]::-webkit-calendar-picker-indicator {
                    display: block;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background: transparent;
                  }
                `}
              </style>
            </div>
          </div>

          <div className="time-input-group">
            <label className="block text-sm font-medium mb-3 uppercase" style={{fontSize: "1.5rem"}}>
              <b>Devolución:</b> {endDate && (
                  new Date(endDate).toLocaleDateString('es-ES', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })
              )}
            </label>
            <div className="relative" style={{
                  textAlign:'center'
                }}>
              <input
                type="time"
                value={times.endTime}
                onChange={(e) => handleTimeChange('endTime', e.target.value)}
                className="w-full px-6 py-4 border-2 border-[#1AA29B] rounded-[40px] focus:ring-2 focus:ring-[#1AA29B] focus:border-[#1AA29B] transition-colors text-lg"
                step="300"
                style={{
                  position: 'relative',width:"10vw",textAlign:'center', height:"5vh"
                }}
              />
            </div>
          </div>

          {!isValidTimeRange() && (
            <div className="text-red-500 text-sm bg-red-50 p-4 rounded-[40px] text-center">
              El período seleccionado debe comenzar antes de que termine
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Horarios;
