import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Tarjetacarrito.css';
import { get_productos } from '../redux/actions/productos';
import { connect } from 'react-redux';
import CategoryList from '../components/CategoryList';
import ProductCard from './ProductCard';
import { getSolicitud } from '../redux/actions/solicitud';

const Productscarrito = ({
    pedidoId,
    productos,
    get_productos,
    carrito,
    searchTerm,
    getSolicitud,
    fecha_creacion, 
    fecha_resolucion
}) => {
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const navigate = useNavigate();
    const [currentPedidoId, setCurrentPedidoId] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        const id = pedidoId || sessionStorage.getItem('currentPedidoId');
        setCurrentPedidoId(id);
        if (fecha_creacion && fecha_resolucion) {
            get_productos(fecha_creacion, fecha_resolucion,id);
        } else {
            navigate('/request');
        }
       
    }, [get_productos, fecha_creacion,fecha_resolucion, pedidoId, navigate]);
    

    useEffect(() => {
        if (productos && searchTerm) {
            const filteredProducts = productos.filter(producto =>
                producto && (
                    (producto.equipo && producto.equipo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (producto.id && producto.id.toString().includes(searchTerm.toLowerCase()))
                )
            );
            setProductosFiltrados(filteredProducts);
        } else {
            setProductosFiltrados(productos || []);
        }
    }, [productos, searchTerm]);

    const handleCategoryChange = useCallback((categoria, seccion = null) => {
        if (categoria === "Todo") {
            setProductosFiltrados(productos || []); // Show all products
        } else {
            const filteredProducts = (productos || []).filter(producto =>
                producto && producto.categoria === categoria && (!seccion || producto.seccion === seccion)
            );
            setProductosFiltrados(filteredProducts);
        }
    }, [productos]);

    return (
        <main className='productos-carrito'>
            
            <CategoryList mostrarPorCategoria={handleCategoryChange} />
            <Link to="/fechas" className="button volver-button">Volver</Link>
            <div className="card-container">
                {productosFiltrados && productosFiltrados.map((producto, index) => (
                    producto && (
                        <ProductCard
                            key={producto.id || index}
                            producto={producto}
                            itemsCarrito={carrito.items}
                        />
                    )
                ))}
            </div>
        </main>
    );
};

const mapStateToProps = state => ({
    productos: state.Productos.productos,
    carrito: state.Solicitud.carrito,
    fecha_creacion: state.Solicitud.fecha_creacion,
    fecha_resolucion: state.Solicitud.fecha_resolucion,
    searchTerm: state.Productos.searchTerm, 
});

export default connect(mapStateToProps, {
    get_productos,
    getSolicitud
})(Productscarrito);