import axios from "axios";
import {
    GET_PEDIDOS_SUCCESS,
    GET_PEDIDOS_FAIL
} from './types'

//GET PEDIDOS POR USUARIO
export const get_pedidos = (params) => async dispatch => {
    /* Al entregar un token JWT, damos la autenticación de usuario, por ende, la información tambien de este (USER.REQUEST) */
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
            }
        };

        try {
            const baseQuery = `?pageSize=${params.pageSize}&page=${params.page}`;
            
            const filterQueries = [
                `estado=${params.estado}`,
                `nroSolicitud=${params.nroSolicitud}`,
                `fechaCreacion=${params.fechaCreacion}`,
                `fechaResolucion=${params.fechaResolucion}`,
                `fechaCreacionDate=${params.fechaCreacionDate}`,
                `nombreCorreo=${params.nombreCorreo}`,
                `fechaCreacionOrder=${params.fechaCreacionOrder}`,
                `fechaResolucionOrder=${params.fechaResolucionOrder}`,
                `fechaCreacionDateOrder=${params.fechaCreacionDateOrder}`,
                `estadoOrder=${params.estadoOrder}`,
            ];
            
            const filterQuery = `&${filterQueries.join('&')}`;
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/${baseQuery}${filterQuery}`, config);
            if (res.status === 200) {
                dispatch({
                    type: GET_PEDIDOS_SUCCESS,
                    payload: res.data
                });
            } else {

                dispatch({
                    type: GET_PEDIDOS_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: GET_PEDIDOS_FAIL
            });
        }
    }
}
