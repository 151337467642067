import React, { useState, useEffect, useRef } from 'react';
import '../css/Veritems.css';
import { FaTimes } from 'react-icons/fa';
import axios from 'axios';
import noImageIcon from '../images/no-image-icon.svg';

const Showitems = ({ onClose, pedido, handleShowCheckList }) => {
    const [items, setItems] = useState([]);
    const modalRef = useRef();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-producto-id-pedido/${pedido.id}`);
                setItems(response.data);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchItems();
    }, [pedido]);

    const getImageUrl = (imagePath) => {
        if (!imagePath) return noImageIcon;
        return `${process.env.REACT_APP_API_URL}${imagePath}`;
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    return (
        <div className='overlayShowItems' onClick={handleClickOutside}>
            <div className="SI-container" ref={modalRef}>
                <div className="table-container">
                    <div className="table-header">
                        <h2 className='SI-h2'>Ítems</h2>
                        <button className="toggle-button" onClick={onClose}>
                            <FaTimes />
                        </button>
                    </div>
                    <div className="table-body">
                        <table className="bg-white text-center item-table">
                            <thead>
                                <tr>
               
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => {
                                    const imageUrl = item.image ? getImageUrl(item.image.image) : noImageIcon;
                                    return (
                                        <tr key={item.id}>
                                            <td><img src={imageUrl} alt={item.equipo} /></td>
                                            <td>
                                                <p>{item.equipo}</p>
                                            </td>
                                            <td>
                                                <div className='show-item-cantidad'>
                                                    <span className="unit-text">{item.cantidad}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="table-footer">
                        <button className="show-checklist-button" onClick={() => handleShowCheckList(pedido)}>
                            Mostrar Checklist
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Showitems;