import React, { useState } from 'react';
import '../css/CheckList.css';
import { format } from 'date-fns';

const CheckListPanol = ({ mostrarCheckList, setMostrarCheckList, solicitud, procesarPedido }) => {
    const [isCommenting, setIsCommenting] = useState(false);
    const [comment, setComment] = useState(' ');
    const [actionType, setActionType] = useState(null); 

  const handleAction = (type) => {
        setActionType(type);
        setIsCommenting(true);
    };

    const submitComment = () => {
        procesarPedido(solicitud.id, actionType === 'accept' ? 'En proceso' : 'Rechazado', comment);
        setMostrarCheckList(false); 
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return format(date, 'HH:mm');
    };

    if (!mostrarCheckList || !solicitud) {
        return null;
    }

     return (
        <div className='checklist_bg' onClick={() => setMostrarCheckList(false)}>
            <div className="checklist" onClick={(e) => e.stopPropagation()}>
                <h1>Checklist</h1>
               
                    <div className='checklist_container'>
                        <p>Taller: {solicitud.taller}</p>
                        <p>Fecha de retiro: {formatDate(solicitud.fecha_creacion)}</p>
                        <p>Hora de retiro: {formatDateTime(solicitud.fecha_creacion)}</p>
                        <p>Fecha de devolucion: {formatDate(solicitud.fecha_resolucion)}</p>
                        <p>Hora de devolucion: {formatDateTime(solicitud.fecha_resolucion)}</p>
                        <p>Ítems: {solicitud.carrito.items.length}</p>
                        <div className='checklist_button'>
                            <button onClick={() => setMostrarCheckList(false)}>Cerrar</button>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default CheckListPanol;
