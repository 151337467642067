import React, { useState, useEffect, useCallback } from 'react';
import '../css/SolicitudList.css';
import { connect } from 'react-redux';
import { call_pedidos, procesar_pedido } from '../redux/actions/resoluciones';
import eyeblue from '../images/eyeblue.svg';
import Showitems from './Showitems';
import CheckListPanol from './CheckListPanol';
import Paginator from './Paginator';
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { BsDashLg } from "react-icons/bs";
import ConfirmationModal from './ConfirmationModal';

const SolicitudList = ({ pedidos, call_pedidos, cantidadPaginas, procesar_pedido }) => {
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [idPedidoSeleccionado, setIdPedidoSeleccionado] = useState(null);
  const [showCheckList, setShowCheckList] = useState(false);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirmMessage, setConfirmMessage] = useState('');


  const [selectedFilters, setSelectedFilters] = useState({
    pageSize: 25,
    page: 1,
    estado: 'undefined',
    nombreCorreo: '',
    fechaCreacion: 'undefined',
    fechaResolucion: 'undefined',
    fechaCreacionDate: 'undefined',
    estadoOrder: 'undefined',
    fechaCreacionOrder: 'undefined',
    fechaCreacionDateOrder: 'desc',
    fechaResolucionOrder: 'undefined',
  });
  const handleConfirmAction = (pedidoId, newState) => {
    let message = '';
    switch (newState) {
      case 'Listo para retiro':
        message = '¿Estás seguro de que quieres marcar este pedido como listo para retiro?';
        break;
      case 'Entregado':
        message = '¿Estás seguro de que quieres marcar este pedido como entregado?';
        break;
      case 'Finalizado':
        message = '¿Estás seguro de que quieres finalizar este pedido?';
        break;
      default:
        message = '¿Estás seguro de que quieres procesar este pedido?';
    }
    setConfirmAction({ pedidoId, newState });
    setConfirmMessage(message);
    setIsConfirmModalOpen(true);
  };


  const handleProcesarPedido = (comment) => {
    if (confirmAction) {
      procesar_pedido(confirmAction.pedidoId, confirmAction.newState, comment);  
    }
    closeConfirmModal();
  };
  

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setConfirmAction(null);
  };
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  };
  const debouncedCallPedidos = useCallback(debounce(call_pedidos, 300), [call_pedidos]);

  const handleMostrarComponente = useCallback((pedidoId) => {
      setIdPedidoSeleccionado(pedidoId);
      setMostrarComponente(true);
  }, []);

  const handleShowItems = (pedido) => {
    setSelectedPedido(pedido);
    setMostrarComponente(true);
  };

  const handleShowCheckList = (pedido) => {
    setSelectedPedido(pedido);
    setShowCheckList(true);
  };

  useEffect(() => {
    debouncedCallPedidos(selectedFilters);
  }, [selectedFilters, debouncedCallPedidos]);

  useEffect(() => {
    window.scrollTo(0, 0);
    call_pedidos(selectedFilters)
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false);
      });
  }, [call_pedidos]);

  const estadoProfesorPanol = (estado) => {
    switch (estado) {
      case 'Recibida':
        return <p>Recibida</p>;
      case 'En proceso':
        return <p>En proceso</p>;
      case 'Listo para retiro':
        return <p>Listo para retiro</p>;
      case 'Entregado':
        return <p>Entregado</p>;
      case 'Finalizado':
          return <p>Finalizado</p>;
      case 'Rechazado':
        return <p>Rechazado</p>;
      default:
        return <p>{estado}</p>;
    }
  };

 
  const obtenerAccion = (estado, pedido) => {
    switch (estado) {
      case 'Recibida':
        return <button className='panol-check-btn' onClick={() => handleShowCheckList(pedido)}>Procesar</button>;
      case 'En proceso':
        return <button className='panol-check-btn' onClick={() => handleConfirmAction(pedido.id, 'Listo para retiro')}>Marcar listo</button>;
      case 'Listo para retiro':
        return <button className='panol-check-btn' onClick={() => handleConfirmAction(pedido.id, 'Entregado')}>Entregar</button>;
      case 'Entregado':
        return <button className='panol-check-btn' onClick={() => openFinalizarModal(pedido.id, pedido.descripcion)}>Finalizar</button>;
      case 'Finalizado':
          return 'Finalizado';
      case 'Rechazado':
        return 'Rechazado';
      default:
        return 'Estado desconocido';
    }
  };
 

  const handlePageSize = (e) => {
    const value = parseInt(e.target.value, 10);
    setSelectedFilters({ ...selectedFilters, pageSize: value });
  }

  const handlePageChange = (page) => {
    setSelectedFilters(prevFilters => ({ ...prevFilters, page }));
  };

  const handleNombreCorreoChange = (e) => {
    // add timeout to prevent calling the API too many times
    const value = e.target.value;
    setSelectedFilters({ ...selectedFilters, nombreCorreo: value });
  }

  const handleStateChange = (e) => {
    const value = e.target.value;
    setSelectedFilters({ ...selectedFilters, estado: value });
  }

  const ESTADOS = {
    undefined: 'Todos',
    'Recibida': 'Recibida',
    'En proceso': 'En proceso',
    'Entregado': 'Entregado',
    'Finalizado': 'Finalizado',
    'Listo para retiro': 'Listo para retiro',
    'Rechazado': 'Rechazado',
  };
  const openFinalizarModal = (pedidoId, currentComment) => {
    setConfirmAction({
      pedidoId: pedidoId,
      newState: 'Finalizado',
      actionType: 'finalizar',
      currentComment: currentComment,  // Pass the current comment to prefill the textarea
    });
    setConfirmMessage('¿Estás seguro que quieres finalizar este pedido?');
    setIsConfirmModalOpen(true);
  };
  const handleSortChange = (filter) => {
    const order = selectedFilters[filter];
    const newOrder = order === 'undefined' ? 'desc' : order === 'desc' ? 'asc' : 'undefined';
    
    setSelectedFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        estadoOrder: 'undefined',
        fechaCreacionDateOrder: 'undefined',
        fechaCreacionOrder: 'undefined',
        fechaResolucionOrder: 'undefined',
        [filter]: newOrder
      };
      return updatedFilters;
    });
  };

  return (
    <div className='solicitudes'>
      <h2>Solicitudes</h2>
      {loading ? (
        <p>Cargando...</p>
      ) : (

        <>
          <form className='filtros-form'>
            <label>
              Cantidad de solicitudes:
              <select
                className='filtros-form-select'
                name="pageSize" 
                id="pageSize" 
                value={selectedFilters.pageSize}
                onChange={handlePageSize} >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </select>
            </label>
          </form>
          <table className='tabla-solicitudes'>
            <thead className='tabla-cabecera-solicitudes'>
              <tr className='tabla-cabecera-solicitudes-titulos'>
                <th>Profesor solicitante
                  <input 
                    type="text" 
                    placeholder="Nombre o correo"
                    className='filtros-form-input'
                    name="nombreCorreo" 
                    id="nombreCorreo"
                    value={selectedFilters.nombreCorreo}
                    onChange={handleNombreCorreoChange}/>
                </th>
                <th onClick={() => handleSortChange('estadoOrder')} className='sortable'>
                  Estado 
                  { selectedFilters.estadoOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.estadoOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }

                  <select
                    className='filtros-form-select'
                    name="estado" 
                    id="estado" 
                    value={selectedFilters.estado}
                    onClick={(e) => e.stopPropagation()}
                    onChange={handleStateChange} >
                      {Object.keys(ESTADOS).map(key => (
                        <option key={key} value={key === '' ? undefined : key}>
                          {ESTADOS[key]}
                        </option>
                      ))}
                  </select>
                </th>
                <th>Ítems</th>
                <th onClick={() => handleSortChange('fechaCreacionDateOrder')} className='sortable'>
                  Fecha de Creación
                  { selectedFilters.fechaCreacionDateOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreacionDateOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }
                  
                  <input
                    type="date"
                    name="fechaCreacionDate"
                    id="fechaCreacionDate"
                    className="filtros-form-input"
                    value={selectedFilters.fechaCreacionDate}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedFilters({ ...selectedFilters, fechaCreacionDate: e.target.value });
                    }}
                  />
                </th>
                <th onClick={() => handleSortChange('fechaCreacionOrder')} className='sortable'>
                Fecha de Retiro
                  { selectedFilters.fechaCreacionOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaCreacionOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }

                  <input
                    type="date"
                    name="fechaCreacion"
                    id="fechaCreacion"
                    className="filtros-form-input"
                    value={selectedFilters.fechaCreacion}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedFilters({ ...selectedFilters, fechaCreacion: e.target.value });
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </th>

                <th onClick={() => handleSortChange('fechaResolucionOrder')} className='sortable'>
                  Fecha de Devolución
                  { selectedFilters.fechaResolucionOrder === 'undefined' ? 
                  <BsDashLg/> : 
                  selectedFilters.fechaResolucionOrder === 'desc' ? 
                  <FaArrowDown/> : 
                  <FaArrowUp/> }

                    <input
                      type="date"
                      name="fechaResolucion"
                      id="fechaResolucion"
                      className="filtros-form-input"
                      value={selectedFilters.fechaResolucion}
                      onChange={(e) => {
                        e.stopPropagation();
                        setSelectedFilters({ ...selectedFilters, fechaResolucion: e.target.value });
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody className='tabla-cuerpo-solicitudes'>
              {pedidos && pedidos.length > 0 && pedidos.map((pedido, index) => (
                <tr key={index}>
                  <td>{pedido.usuario.nombre}</td>
                  <td>{estadoProfesorPanol(pedido.estado)}</td>
                  <td>
                    {pedido.items}
                    <img
                      src={eyeblue}
                      alt='icon-eye-blue'
                      id='Ojito'
                      onClick={() => handleShowItems(pedido)}
                    />
                  </td>
                  <td>{(new Date(pedido.pedido_creation_date).toLocaleDateString('es-ES'))}</td>
                  <td>{new Date(pedido.fecha_creacion).toLocaleDateString('es-ES')}</td>
                  <td>{new Date(pedido.fecha_resolucion).toLocaleDateString('es-ES')}</td>
                  <td>{obtenerAccion(pedido.estado, pedido)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator 
        currentPage={selectedFilters.page} 
        handlePageChange={handlePageChange} 
        totalPageCount={cantidadPaginas} 
      />
        </>
      )}
      {mostrarComponente && <Showitems onClose={() => setMostrarComponente(false)} pedido={selectedPedido} handleShowCheckList={handleShowCheckList} />}
      {showCheckList && (
        <CheckListPanol
          mostrarCheckList={showCheckList}
          setMostrarCheckList={setShowCheckList}
          solicitud={selectedPedido}
          procesarPedido={procesar_pedido}
        />
      )}
     <ConfirmationModal
  isOpen={isConfirmModalOpen}
  onClose={closeConfirmModal}
  onConfirm={handleProcesarPedido}
  message={confirmMessage}
  actionType={confirmAction?.actionType}  // Pass the action type
  currentComment={confirmAction?.currentComment}  // Pass the current comment
/>
    </div>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.Auth.isAuthenticated,
  pedidos: state.Pedidos.pedidosUsuario,
  cantidadPaginas: state.Pedidos.totalPaginas
});

export default connect(mapStateToProps, {
  call_pedidos,
  procesar_pedido,
})(SolicitudList);