import React, { useEffect, useRef, useState } from 'react';
import '../css/ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message, actionType, currentComment }) => {
  const modalRef = useRef(null);
  const [comment, setComment] = useState(currentComment || '');  // Initialize with current comment if provided

  useEffect(() => {
    // Reset comment when modal is opened
    if (isOpen && actionType === 'finalizar') {
      setComment(currentComment || '');  // Prefill with current comment or leave it empty
    }
  }, [isOpen, actionType, currentComment]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(comment);  // Pass the comment back to the parent component
  };

  return (
    <div className="confirmation-modal-overlay">
      <div ref={modalRef} className="confirmation-modal-content">
        <p>{message}</p>

        {/* Conditionally show the comment textarea only when the action is "finalizar" */}
        {actionType === 'finalizar' && (
          <textarea className="confirmation-modal-textarea"
            placeholder="Agregar o editar comentario"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        )}

        <div className="confirmation-modal-buttons">
          <button onClick={handleConfirm}>Sí</button>
          <button onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
