import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { addItemSolicitud, deleteItemSolicitud, deleteItemSolicitudCompletamente } from "../redux/actions/solicitud";
import noImageIcon from '../images/no-image-icon.svg';
import { FaTrash, FaTimes } from 'react-icons/fa';
const ProductoCarrito = ({
    producto,
    itemsCarrito,
    addItemSolicitud,
    deleteItemSolicitud,
    deleteItemSolicitudCompletamente,
    allProductos // New prop from Redux store
}) => {
    const [cantidad, setCantidad] = useState(0);

    useEffect(() => {
        if (itemsCarrito) {
            const itemInCarrito = itemsCarrito.find(item => item.producto.id === producto.id);
            if (itemInCarrito) {
                setCantidad(itemInCarrito.cantidad);
            } else {
                setCantidad(0);
            }
        }
    }, [itemsCarrito, producto.id]);

    const incrementarCantidad = () => {
        // Find the full product data from allProductos
        const fullProductData = allProductos.find(p => p.id === producto.producto.id);
        
        if (fullProductData && cantidad < fullProductData.cantidad) {
            addItemSolicitud(fullProductData, producto.producto);
        } else {
            console.log(`No se puede agregar más del producto ${producto.id}. Cantidad máxima alcanzada.`);
        }
    };

    const eliminarProducto = () => {
        deleteItemSolicitudCompletamente(producto);
        setCantidad(0);
    };

    const getImageUrl = (imagePath) => {
        if (!imagePath) return noImageIcon;
        return `${process.env.REACT_APP_API_URL}${imagePath}`;
    };

    const imageUrl = producto.image 
        ? getImageUrl(producto.image.image) 
        : (Array.isArray(producto.producto.images) && producto.producto.images[0] && producto.producto.images[0].image 
            ? getImageUrl(producto.producto.images[0].image) 
            : noImageIcon);

            return(
              <div className='d-flex flex-column'>
                        <p>{producto.producto.equipo}</p>
                        <div className='d-flex justify-content-center'>
                          <img src={imageUrl} alt={producto.producto.equipo} />
                          <footer>
                            <div className="item-counter">
                              <button 
                                className="delete-button botones-carrito"
                                onClick={() => deleteItemSolicitud(producto,producto.producto,0)}
                              >
                                <FaTrash />
                              </button>
                              <button 
                                className="minus-button botones-carrito"
                                onClick={() => deleteItemSolicitud(producto,producto.producto)}
                              >
                                -
                              </button>
                              <span className="item-count">{producto.cantidad} un.</span>
                              <button 
                                className="plus-button botones-carrito"
                                onClick={() => incrementarCantidad(producto,producto.producto)}
                              >
                                +
                              </button>
                            </div>
                          </footer>
                        </div>
                      </div>
          );
      }

const mapStateToProps = (state) => ({
    carrito: state.Solicitud.carrito,
    allProductos: state.Productos.productos // Add this to access all products
});

export default connect(mapStateToProps, {
    addItemSolicitud,
    deleteItemSolicitud,
    deleteItemSolicitudCompletamente
})(ProductoCarrito);