import React, { useEffect, useRef } from 'react';
import '../css/GestionarUsuarioModal.css';

const GestionarUsuarioModal = ({ isOpen, onClose, onSubmit, formValues, handleInputChange, isEditMode }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="GU-modal-overlay">
      <div className="GU-modal-content" ref={modalRef}>
        <button className="GU-modal-close" onClick={onClose}>X</button>
        <form onSubmit={onSubmit} className="GU-form">
          <h2 className="GU-form-title">{isEditMode ? 'Editar Usuario' : 'Crear Usuario'}</h2>
          <div className="GU-form-group">
            <input
              type="text"
              name="nombre"
              className="GU-form-input"
              placeholder="Nombre"
              value={formValues.nombre}
              onChange={handleInputChange}
            />
          </div>
          <div className="GU-form-group">
            <input
              type="email"
              name="email"
              className="GU-form-input"
              placeholder="Email"
              value={formValues.email}
              onChange={handleInputChange}
            />
          </div>
            <div className="GU-form-group">
              <input
                type="password"
                name="password"
                className="GU-form-input"
                placeholder="Password"
                value={formValues.password}
                onChange={handleInputChange}
              />
            </div>
          <div className="GU-form-group">
            <label className="GU-form-checkbox-label">
              <input
                type="checkbox"
                name="isStaff"
                className="GU-form-checkbox"
                checked={formValues.isStaff}
                onChange={handleInputChange}
              />
              Es Admin
            </label>
         
            <label className="GU-form-checkbox-label">
              <input
                type="checkbox"
                name="estado"
                className="GU-form-checkbox"
                checked={formValues.estado}
                onChange={handleInputChange}
              />
              Estado Activo
            </label>
          </div>
          <button type="submit" className="GU-form-submit">
            {isEditMode ? 'Editar Usuario' : 'Crear Usuario'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default GestionarUsuarioModal;